import {AutocompleteArrayInput, Create, Edit, ReferenceArrayInput, required, TabbedForm, TextInput} from 'react-admin';
import {getUserFullName} from 'utils';
import React from 'react';

const form = (
    <TabbedForm>
        <TabbedForm.Tab label='Контент'>
            <TextInput label='Название' source='name' fullWidth validate={required()} />
            <ReferenceArrayInput source='managersIds' reference='user'>
                <AutocompleteArrayInput
                    label='Руководитель округа'
                    filterToQuery={(searchText: any) => ({search: searchText})}
                    optionText={(record) => getUserFullName(record)}
                    fullWidth
                />
            </ReferenceArrayInput>
        </TabbedForm.Tab>
    </TabbedForm>
);

export const DistrictCreate = () => {
    return <Create>{form}</Create>;
};

export const DistrictEdit = () => {
    return <Edit>{form}</Edit>;
};
